import "@Styles/Shared/UserProfile/Survey/survey_sm.less";
import "@Styles/Shared/UserProfile/Survey/surveyIntro_lg.less";
import "@Styles/Shared/UserProfile/Survey/surveyIntro_md.less";
import "@Styles/Shared/UserProfile/Survey/surveyIntro_sm.less";
import "@Styles/Shared/UserProfile/Survey/surveyNavigation_sm.less";
import "@Styles/Shared/UserProfile/Survey/surveyProgressBar_md.less";
import "@Styles/Shared/UserProfile/Survey/surveyProgressBar_sm.less";
import "@Styles/Shared/UserProfile/Survey/surveyRegistration_lg.less";
import "@Styles/Shared/UserProfile/Survey/surveyRegistration_md.less";
import "@Styles/Shared/UserProfile/Survey/surveyRegistration_sm.less";
import "@Styles/Shared/UserProfile/Survey/PB/survey_sm.less";
import "@Styles/Shared/UserProfile/Survey/PB/surveyIntro_sm.less";
import "@Styles/Shared/UserProfile/Survey/PB/surveyProgressBar_sm.less";
import "@Styles/Shared/UserProfile/Survey/PB/surveyRegistration_sm.less";
import "@Styles/Shared/UserProfile/Survey/survey_md.less";
import "@Styles/Shared/UserProfile/Survey/survey_lg.less";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import intro from './surveyIntro';
import registration from './surveyRegistration';
import navigation from './surveyNavigation';
import modal from '@Shared/Common/Scripts/Components/modalBase';
import { SURVEY_EVENTS, MULTI_CHOICE_QUESTION_TYPE } from '../_constants';
import { mapGetters, mapActions } from 'vuex';
import { cookie } from '@Shared/Common';
/* istanbul ignore next: dynamic import */

var progressBar = function progressBar() {
  return import(
  /* webpackChunkName: 'surveyProgressBar' */
  './surveyProgressBar');
};

var multiChoiceQuestion = function multiChoiceQuestion() {
  return import(
  /* webpackChunkName: 'multiChoiceInput' */
  '../Inputs/multiChoiceQuestionInput');
};

export default {
  components: {
    modal: modal,
    intro: intro,
    registration: registration,
    navigation: navigation,
    progressBar: progressBar,
    // Ensure that component name matches with QuestionType enum values
    'MultiSelect': multiChoiceQuestion
  },
  data: function data() {
    return {
      isVisible: false,
      localAnswers: {}
    };
  },
  computed: _objectSpread({}, mapGetters('_surveyStore', ['currentQuestion', 'currentAnswer', 'currentValidationClass', 'isCurrentAnswerValid', 'isIntroductionStep', 'isRegistrationStep', 'isAuthenticatedUser', 'isInModal', 'answers', 'errorMessage', 'config', 'progressBarComponent', 'stepCount']), {
    showQuestionComponent: function showQuestionComponent() {
      return !!(this.currentQuestion && this.localAnswers);
    },
    hideHeaderFooter: function hideHeaderFooter() {
      return this.isIntroductionStep ? 'hideHeaderFooter' : '';
    }
  }),
  watch: {
    localAnswers: {
      handler: function handler(val) {
        if (JSON.stringify(val) !== JSON.stringify(this.answers)) {
          this.setAnswers(val);
        }
      },
      deep: true
    },
    currentAnswer: {
      handler: function handler(val) {
        var _this = this;

        var localAnswer = _this.currentQuestion && _this.currentQuestion.id && _this.localAnswers[_this.currentQuestion.id];

        if (localAnswer && JSON.stringify(val) !== JSON.stringify(localAnswer)) {
          this.$set(_this.localAnswers, _this.currentQuestion.id, val);
        }
      },
      deep: true
    }
  },
  methods: _objectSpread({}, mapActions('_surveyStore', ['setConfig', 'setRegistrationConfig', 'setStaticQuestions', 'setSteps', 'setErrorMessage', 'setAnswerErrorMessage', 'setValidationClass', 'setAnswers', 'saveAnswersToCookie', 'setCurrentStepNumber']), {
    init: function init(config) {
      var _this2 = this;

      if (config && config.settings && config.settings.questions) {
        this.setConfig(_objectSpread({}, config.settings, {
          isAuthenticatedUser: config.isAuthenticatedUser
        }));
        this.setRegistrationConfig(_objectSpread({}, config.registrationConfig));
        var steps = [];

        if (!this.isSurveyCompleted()) {
          // store original questions (needed for as choices are added and removed)
          this.setStaticQuestions(config.settings.questions); // set up steps

          steps = [{
            stepIndex: 0,
            isIntroductionStep: true
          }]; // set up questions

          this.config.questions.forEach(function (q, i) {
            if (!q.hasOwnProperty('profileQuestionId')) {
              return;
            }

            var answers = {
              value: q.questionType === MULTI_CHOICE_QUESTION_TYPE ? [] : '',
              isValid: null
            };

            var question = _objectSpread({}, q, {
              requiredMessage: _this2.config.invalidAnswerErrorMessage,
              id: q.profileQuestionId
            });

            question.answers = _objectSpread({}, answers);

            _this2.$set(_this2.localAnswers, question.id, answers);

            steps.push({
              stepIndex: i + 1,
              question: question
            });
          });
        }

        if (!this.isAuthenticatedUser) {
          // only prompt user to sign up/log in if they aren't currently known
          var stepIndex = steps.length;
          steps.push({
            stepIndex: stepIndex,
            isRegistrationStep: true
          });
        }

        this.setSteps(steps);

        if (!this.isAuthenticatedUser && this.isSurveyCompleted()) {
          this.setCurrentStepNumber(this.stepCount - 1);
        } // event handling for showing the survey, which is externally controlled


        document.addEventListener(SURVEY_EVENTS.show, function (event) {
          _this2.isVisible = event.detail === true;
        });
        var customEvent = new Event(SURVEY_EVENTS.ready);
        document.dispatchEvent(customEvent);
      }
    },
    focusStart: function focusStart() {
      // pick a navigation button or the close button to focus on
      var elem = this.$el.querySelector('.nextButton') || this.$el.querySelector('.completeButton') || this.$el.querySelector('.registrationLink') || this.$el.querySelector('.modalCloseButton');
      elem && elem.focus();
    },
    setIsAnswerValid: function setIsAnswerValid(_ref) {
      var _this3 = this;

      var isValid = _ref.isValid,
          maxReached = _ref.maxReached;

      if (this.currentQuestion) {
        var answer = this.localAnswers[this.currentQuestion.id];

        if (answer) {
          answer.isValid = isValid;
          this.setErrorMessage(''); // wait until next tick for answers in store to get updated

          this.$nextTick(function () {
            _this3.setValidationClass();

            if (!!isValid) {
              maxReached && _this3.setErrorMessage(_this3.currentQuestion.maximumErrorMessage);
            } else {
              _this3.setAnswerErrorMessage();
            }
          });
        }
      }
    },
    complete: function complete() {
      var _this = this;

      if (_this.isCurrentAnswerValid) {
        _this.setErrorMessage('');

        _this.saveAnswersToCookie();

        if (_this.isAuthenticatedUser) {
          var customEvent = new Event(SURVEY_EVENTS.complete);
          document.dispatchEvent(customEvent);
        }
      } else {
        _this.setErrorMessage(_this.genericErrorMessage);

        _this.setIsAnswerValid({
          isValid: false,
          maxReached: false
        });
      }
    },
    onModalClose: function onModalClose() {
      var customEvent = new Event(SURVEY_EVENTS.dismissed);
      document.dispatchEvent(customEvent);
    },
    isSurveyCompleted: function isSurveyCompleted() {
      return cookie.getCookie("gmi.survey.completed") == "1";
    }
  })
};