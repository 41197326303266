import "@Styles/Shared/UserProfile/Survey/surveyIntro_sm.less";
import "@Styles/Shared/UserProfile/Survey/PB/surveyIntro_sm.less";
import "@Styles/Shared/UserProfile/Survey/surveyIntro_md.less";
import "@Styles/Shared/UserProfile/Survey/surveyIntro_lg.less";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapActions, mapGetters } from 'vuex';
export default {
  data: function data() {
    return {
      carouselImages: [],
      timer: {},
      animationActive: false,
      currentIndex: 0
    };
  },
  computed: _objectSpread({}, mapGetters('_surveyStore', ['config', 'currentStepNumber', 'isAuthenticatedUser']), {
    carouselButtonText: function carouselButtonText() {
      return this.animationActive ? this.config.imageCarouselPauseButtonText : this.config.imageCarouselPlayButtonText;
    },
    carouselAvailable: function carouselAvailable() {
      return this.carouselImages && this.carouselImages.length > 1;
    },
    showLoginContainer: function showLoginContainer() {
      return !this.isAuthenticatedUser;
    }
  }),
  created: function created() {
    this.$_initializeCarousel();
  },
  methods: _objectSpread({}, mapActions('_surveyStore', ['setCurrentStepNumber']), {
    start: function start() {
      this.setCurrentStepNumber(this.currentStepNumber + 1);
    },
    logInLinkEvent: function logInLinkEvent() {
      GeneralMills.Analytics.raiseEvent({
        event: 'login_prompt',
        position: 'RJFY'
      });
    },
    animateCarousel: function animateCarousel() {
      this.animationActive ? this.$_pauseCarousel() : this.$_playCarousel();
    },
    $_initializeCarousel: function $_initializeCarousel() {
      if (this.config.imageCarouselImages && this.config.imageCarouselImages.length > 0) {
        this.carouselImages = this.config.imageCarouselImages.map(function (image, index) {
          return {
            imageUrl: image,
            visible: index === 0 ? true : false
          };
        });
        if (this.carouselAvailable) this.$_playCarousel();
      }
    },
    $_nextCarouselImage: function $_nextCarouselImage() {
      var _this = this;

      var oldIndex = this.currentIndex;
      this.currentIndex = this.currentIndex + 1 === this.carouselImages.length ? 0 : this.currentIndex + 1;
      this.carouselImages[this.currentIndex].visible = true;
      setTimeout(function () {
        _this.carouselImages[oldIndex].visible = false;
      }, 500);
    },
    $_playCarousel: function $_playCarousel() {
      var _this2 = this;

      this.timer = setInterval(function () {
        _this2.$_nextCarouselImage();
      }, 3000);
      this.animationActive = true;
    },
    $_pauseCarousel: function $_pauseCarousel() {
      clearInterval(this.timer);
      this.animationActive = false;
    }
  })
};