import "@Styles/Shared/UserProfile/Inputs/birthdateInput_sm.less";
import "@Styles/Shared/UserProfile/Inputs/birthdateInput_lg.less";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import validatedInputBase from './validatedInputBase';
export default {
  extends: validatedInputBase,
  props: {
    value: {
      type: String
    },
    config: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      isFocused: false,
      dateItems: {
        MM: {
          maxValue: 12,
          minValue: 1,
          maxLength: 2,
          value: '',
          itemClass: 'dateInputMonth',
          label: 'birthdayMonthLabel',
          autocomplete: 'bday-month'
        },
        YYYY: {
          maxValue: 9999,
          minValue: 1910,
          maxLength: 4,
          value: '',
          itemClass: 'dateInputYear',
          label: 'birthdayYearLabel',
          autocomplete: 'bday-year'
        }
      }
    };
  },
  computed: {
    dateFormat: function dateFormat() {
      var dateFormat = ['MM', 'YYYY'];
      return dateFormat;
    }
  },
  methods: {
    onDateFocus: function onDateFocus() {
      this.isFocused = true;
    },
    onDateBlur: function onDateBlur() {
      var _this = this;

      this.isFocused = false; // wait until next tick - could be shifting focus between M/D/Y

      this.$nextTick(function () {
        var focusedElement = document.activeElement;
        var month = _this.dateItems.MM.value;
        var year = _this.dateItems.YYYY.value;

        if (!focusedElement || focusedElement.parentElement != _this.$refs.dateInputBox) {
          var dateStr = "".concat(year, "-").concat(month);

          try {
            if (year.length !== 4 || !month) {
              // JS tries to be smart when creating dates so let's not allow any missing info
              _this.setIsValid(false);
            } else if (year < 1910) {
              _this.setIsValid(false);
            } else {
              var date = new Date(dateStr);
              dateStr = date.toISOString().substring(0, 10); // do not allow current/future date

              date.setDate(date.getDate() + 1);

              _this.setIsValid(date < Date.now());
            }
          } catch (e) {
            _this.setIsValid(false);
          } finally {
            if (month || year) {
              // always put a value if user has input anything
              // as a backup we will catch invalid dates on the server
              _this.$emit('input', dateStr);
            }
          }
        }
      });
    },
    handleInput: function handleInput(event, item, index) {
      var val = event.target.value;

      if (val > 0 && val <= item.maxValue && val >= item.minValue && val.length === item.maxLength) {
        if (index < 1) {
          var nextElement = this.$refs["input".concat(index + 1)];

          if (nextElement && nextElement[0]) {
            nextElement[0].focus();
          }
        }
      } else if (val && val.length > item.maxLength) {
        item.value = val.slice(0, item.maxLength);
      }
    }
  }
};